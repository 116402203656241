<template>
  <view-item title=""></view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {}
  },

  mounted() {}
})
</script>
